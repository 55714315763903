import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "../Loadable";
import { MiningWalletGuard } from "src/guards/AuthGuard";

const FundTransfer = Loadable(
  lazy(() => import("src/pages/user/financial/fundTransfer"))
);

const EWallet = Loadable(
  lazy(() => import("src/pages/user/financial/ewallet"))
);

const DepositWallet = Loadable(
  lazy(() => import("src/pages/user/financial/deposit"))
);

const AddCredit = Loadable(
  lazy(() => import("src/pages/user/financial/deposit/addCredit"))
);

const RequestPayout = Loadable(
  lazy(() => import("src/pages/user/financial/requestPayout/index"))
);
const BuyPackageWallet = Loadable(
  lazy(() => import("src/pages/user/financial/buy-package/index"))
);

const MainWallet = Loadable(
  lazy(() => import("src/pages/user/financial/main-wallet/index"))
);

const CommissionWallet = Loadable(
  lazy(() => import("src/pages/user/financial/commission-wallet/index"))
);
const MiningWallet = Loadable(
  lazy(() => import("src/pages/user/financial/mining-wallet/index"))
);

const financial = {
  path: "financial",
  children: [
    { index: true, element: <Navigate to="e-wallet" /> },
    { path: "e-wallet", element: <EWallet /> },
    {
      path: "deposit-wallet",
      children: [
        { index: true, element: <DepositWallet /> },
        { path: "add-credit", element: <AddCredit /> },
      ],
    },
    { path: "funds-transfer", element: <FundTransfer /> },
    { path: "payout", element: <RequestPayout /> },
    { path: "buy-package-wallet", element: <BuyPackageWallet /> },
    {
      path: "main-wallet",
      element: <MainWallet />,
    },
    { path: "commission-wallet", element: <CommissionWallet /> },
    {
      path: "mining-wallet",
      element: (
        <MiningWalletGuard>
          <MiningWallet />
        </MiningWalletGuard>
      ),
    },
  ],
};

export default financial;
